<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row mb-3">
              <div class="col-6">
                <h4>
                  {{ `${id ? 'Update' : "Add"} testimonial` }}
                </h4>
                <router-link :to="{ name: 'dashboard' }">
                  Dashboard
                </router-link>
                \
                <router-link :to="{name:'testimonial'}">
                  Testimonials
                </router-link>
              </div>
              <!--                            <div class="col-2 ">-->
              <!--                                <router-link :to="{name:'testimonial'}" class="btn btn-primary">-->
              <!--                                    <i class="fas fa-arrow-left"></i>-->
              <!--                                    Back-->
              <!--                                </router-link>-->
              <!--                            </div>-->
              <!--                            <div class="col-4">-->
              <!--                                <h4>Add Slider Image</h4>-->
              <!--                            </div>-->
              <div class="col-6 text-right"></div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <v-text-field outlined dense label="Title" v-model="testimonial.title"></v-text-field>
                <span class="text-danger" v-if="$v.testimonial.title.$error">This information is required</span>
              </div>
              <div class="col-md-6">
                <v-text-field outlined dense label="Position"
                              v-model="testimonial.position"></v-text-field>
              </div>
              <div class="col-12 pb-6">
                <label for="">
                  Description <span class="badge badge-primary">({{descriptionWords}})</span>
                </label>
                <ckeditor
                  :config="editorConfig"
                  v-model="testimonial.description"
                ></ckeditor>
                <span class="text-danger" v-if="!$v.testimonial.description.countWord">Less than forty words should be used. </span>
              </div>
              <div class="col-md-12">
                <v-file-input accept="image/*"
                              outlined dense prepend-icon=""
                              prepend-inner-icon="mdi-file"
                              @change="checkSize($event)" v-model="testimonial.image"
                              label="Image"></v-file-input>
                <span class="text-danger" v-if="$v.testimonial.image.$error">This information is required</span>
                <span class="text-danger" v-if="sizeExceed">Please upload a file upto 20MB</span>
              </div>
              <div class="col-md-6 ">
                <v-text-field outlined dense label="Type" v-model="testimonial.type"></v-text-field>
              </div>
              <div class="col-md-6">
                <v-text-field outlined dense label="Name" v-model="testimonial.name"></v-text-field>
              </div>
              <div class="col-md-6">
                <v-text-field outlined dense label="Job Title"
                              v-model="testimonial.job_title"></v-text-field>
              </div>
              <div class="col-md-6">
                <v-select
                    :items="programs"
                    v-model="testimonial.program_id"
                    item-value="id"
                    item-text="title"
                    dense
                    outlined
                    label="Select program"
                >

                </v-select>

              </div>
              <div class="col-md-6">
                <v-rating
                    v-model="testimonial.rating"
                    background-color="orange lighten-3"
                    color="orange"
                    large
                ></v-rating>
<!--                <b-form-rating v-model="testimonial.rating" show-value precision="2"></b-form-rating>-->
              </div>


              <div class="col-md-6">
                <v-switch v-model="testimonial.show_to_program" name="check-button" label=" Show on program">
                </v-switch>
                <!-- <span class="text-danger" v-if="$v.testimonial.is_active.$error">Title is required</span> -->
              </div>
              <div class="col-md-12 ">
                Status
                <v-switch v-model="testimonial.is_active"  :label="testimonial.is_active ? 'Active' : 'Inactive'">

                </v-switch>
                <!-- <span class="text-danger" v-if="$v.testimonial.is_active.$error">Title is required</span> -->
              </div>
              <div class="col-12">
                <img
                    v-if="testimonial.image_path"
                    :src="testimonial.image_path['thumb']"
                    alt
                    style="height:75px"
                    class="img-thumbnail"
                />
              </div>

              <div class="col-12 mt-5">
                <div class="float-right">
                  <v-btn
                      class="mr-2 cancel-btn"
                      text
                      x-large
                      @click="redirectTo"
                  >Cancel
                  </v-btn>
                  <v-btn v-if="checkIsAccessible('testimonial','create')"
                         class="btn btn-primary"
                      depressed   x-large
                      :disabled="sizeExceed"
                      @click="createOrUpdate"
                      :loading="isBusy"
                  >Save
                  </v-btn>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ProgramService from "@/core/services/level/program/ProgramService";
import TestimonialService from "@/core/services/testimonial/TestimonialService";
import {required} from "vuelidate/lib/validators";

const testimonial = new TestimonialService();
const programService = new ProgramService();

export default {
  name: "testimonial-new",
  validations: {
    testimonial: {
      title: {required},
      image: {required},
      description: {
        countWord: function (value) {
          if(value) {
            let trimString = value.trim();
            let normalString = trimString.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
            let words = normalString.split(/\s+/);
            return words.length <= 41;
          }
          return false;
        }
      }
    }
  },
  data() {
    return {
      isBusy: false,
      sizeExceed: false,
      value: 3.5,
      programs: [],
      testimonial: {
        id: null,
        title: null,
        link: null,
        image: null,
        position: null,
        new_tab: 0,
        rating:0,
        description: null,
        type: null,
        is_active: true
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
    };
  },
  mounted() {
    this.id ? this.getTestimonial(this.id) : "";
    this.getPrograms();
  },
  computed: {
    id() {
      return this.$route.params.id ? this.$route.params.id : false;
    },
    descriptionWords () {
      if(this.testimonial.description) {
        let trimString = this.testimonial.description.trim();
        let normalString = trimString.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
        let words = normalString.split(/\s+/);
        return words.length;
      }
      return 0;
    }
  },
  methods: {
    getTestimonial(id) {
      testimonial.show(id).then(response => {
        this.testimonial = response.data.testimonial;
        this.testimonial.is_active ? (this.testimonial.is_active = true) : false;
        this.testimonial.new_tab ? (this.testimonial.new_tab = true) : false;
        this.testimonial.show_to_program ? (this.testimonial.show_to_program = true) : false;
        this.edit = true;
      });
    },
    getPrograms() {
      programService.all().then(response => {
        this.programs = response.data;
      });
    },
    redirectTo() {
      this.$router.push({name: "testimonial"});
    },
    checkSize(file) {
      let size = file.size / 1024 / 1024;
      let mbSize = size.toFixed(2);
      if (mbSize > 20) {
        this.sizeExceed = true;
      } else {
        this.sizeExceed = false;
      }
    },
    saveTestimonial(fd) {
      this.isBusy = true;
      testimonial
          .store(fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information added");
            this.$tabs.close().then(response => {
              this.$router.push({name: "testimonial"});
            });
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },

    createOrUpdate() {
      this.$v.testimonial.$touch();
      if (this.$v.testimonial.$error) {
        setTimeout(() => {
          this.$v.testimonial.$reset();
        }, 3000);
      } else {
        let fd = this.generateFd();
        // console.log(fd);
        if (this.edit) {
          this.updateTestimonial(fd);
        } else {
          this.saveTestimonial(fd);
        }
      }
    },
    updateTestimonial(fd) {
      this.isBusy = true;
      testimonial
          .update(this.testimonial.id, fd)
          .then(response => {
            this.isBusy = false;
            this.$snotify.success("Information updated");
            this.$tabs.close().then(response => {
              this.$router.push({name: "testimonial"});
            });
          })
          .catch(error => {
            this.isBusy = false;
            this.$snotify.error("Something Went Wrong");
          });
    },
    generateFd() {
      let fd = new FormData();
      for (let key in this.testimonial) {
        if (
            key == "image" &&
            this.testimonial["image"] &&
            this.testimonial["image"] != null
        ) {
          if (typeof this.testimonial["image"] == "object") {
            fd.append("image", this.testimonial[key]);
          }
        } else if (key == "new_tab" && this.testimonial["new_tab"]) {
          fd.append("new_tab", this.testimonial.new_tab ? 1 : 0);
        } else if (key == "is_active") {
          fd.append("is_active", this.testimonial.is_active ? 1 : 0);
        } else if (key == "show_to_program") {
          fd.append("show_to_program", this.testimonial.show_to_program ? 1 : 0);
        } else {
          if (key != "id" && this.testimonial[key]) {
            fd.append(key, this.testimonial[key]);
          }
        }
      }
      return fd;
    }
  }
};
</script>
